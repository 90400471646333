import React from "react";
import { Wrapper, Logo } from './styles'
import LogoImg  from '../../assets/Logo/oss-white.svg'

const Sidebar = () => {

    return (<Wrapper>
        <Logo src={LogoImg} alt="Logo Image" />
    </Wrapper>)


}

export default Sidebar