import styled from 'styled-components'

export const Wrapper = styled.div`
    background: linear-gradient(0,#ACC2E8,#028592);
    height: calc(100vh - 60px);
    width: 133px;
    padding-top: 60px;
`

export const Logo = styled.img`
    display: flex;
    margin: auto;
    width: 77px;
    object-fit: contain;
`