import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
`
export const MainSection = styled.div`
    background: #EBEAE7;
    width: calc(100% - 40px);
    padding-left: 40px;
    height: 100vh;
`
export const PageTitle = styled.h1`
    color: #000000;
    font-size: 39px;
`

export const SubTitle = styled.h1`
    color: #02808D;
    font-size: 21px;
    font-weight: 500;
    margin-top: -20px;
`

export const SectionTitle = styled.h2`
    color: #000000;
    font-size: 32px;
    display: flex;
    justify-content: center;
`

export const SectionSubTitle = styled.label`
    color: #0D0D0D;
    font-size: 18px;
    font-weight: 500;
`

export const Row = styled.div`
    display: flex;
    ${props => props.timeline && css`
        border-left: 10px solid ${props.borderColor};
        min-height: ${props => props.title ? '68px' : '30px'};
        align-items: center;
    `}
`
export const Box = styled.div`
    background: #FFFFFF;
    padding: ${props => props.noPadding ? 0 : '20px'};
    height: calc(100vh - ${props => props.noPadding ? '160px' : '200px'});
    width: 546px;
    box-shadow: 3px 3px 15px #00000029;
    border-radius: 20px;
    margin-right: 25px;
`

export const CustomerSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 320px);
    overflow: scroll;
    align-content: baseline;
`
export const CustomerBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 124px;
    height: 111px;
    margin-right: 26px;
    background: ${props => props.active ? '#028592' : '#E8F1F2'};
    border-radius: 11px;
    margin-top: 15px;
`

export const CustomerName = styled.label`
    color: ${props => !props.active ? '#028592' : '#FFFFFF'};
    font-size: 15px;
    margin-top: auto;
    display: flex;
    font-weight: 500;
`

export const BoxHeader = styled.div`
    background: #2A808D;
    width: calc(100% - 32px);
    height: 160px;
    padding: 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`

export const TimelineSection = styled.div`
    overflow: scroll;
    height: calc(100% - 210px);
`

export const CustomerImageBlue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #028592;
    width: 51px;
    height: 51px;
    border-radius: 26px;
`
export const CustomerImage = styled.img`
    width: 34px;
    height: 30px;
`

export const TimelineIcon = styled.img`
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
`

export const TimelineLabel = styled.label`
    font-size: 17px;
    color: #000000;
    font-weight: 600;
    ${props=>props.date && css`
        font-size: 15px;
    `}
`
export const DropdownArrow = styled.img`
    display: flex;
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: 15px;

    ${props => props.open && css`
        transform: rotate(180deg);
    `}
`

export const ViewProfileButton = styled.button`
    display: flex;
    border: none;
    width: 180px;
    height: 56px;
    border-radius: 15px;
    background: white;
    color: #2A808D;
    font-size: 15px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`

export const Content = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
`

export const ContentLabel = styled.label`
   display: flex;
   color: #707070;
   font-size: 15px;
   font-weight: 500;
   ${props => props.right && css`
        margin-left: auto;
   `}
`