import React, { useEffect, useState } from "react";

import Sidebar from '../../Components/Sidebar'
import { Wrapper, MainSection, PageTitle, SubTitle, Box, Row, SectionTitle, SectionSubTitle, CustomerBox, CustomerSection, CustomerName, BoxHeader, CustomerImageBlue, CustomerImage, TimelineSection, TimelineIcon, TimelineLabel, DropdownArrow, ViewProfileButton, Content, ContentLabel } from "./styles";
import ossBlue from '../../assets/Logo/oss-blue.svg'

import customerImg from '../../assets/Dashboard/Customer.svg'
import downArrow from '../../assets/Dashboard/downArrow.svg'
import information from '../../assets/Dashboard/information.svg'
import demographic from '../../assets/Dashboard/demographic.png'
import pyschographic from '../../assets/Dashboard/pyschographic.png'

import axios from 'axios'
import { format } from "date-fns";

const Dashboard = ({ props }) => {

    const [selectedCustomer, setSelectedCustomer] = useState()
    const [events, setEvents] = useState([])
    const [datapoints, setDatapoints] = useState([])
    const [openedDropdowns, setOpenedDropdowns] = useState([])

    const customers = [
        { id: "1", name: 'Mary' },
        { id: "2", name: 'Jason' },
        { id: "3", name: 'John' },
        { id: "4", name: 'Carl' },
        { id: "5", name: 'Smith' },
    ]

    const singleViewSections = [
        { name: 'Demographic', img: demographic, borderColor: '#F8B195' },
        { name: 'Sales Data', img: ossBlue, borderColor: '#D6F8B8' },
        { name: 'Timeline', img: ossBlue, borderColor: '#8FBBAF' },
        { name: 'Pyschographic', img: pyschographic, borderColor: '#F67280' },
    ]

    const contentForSections = {
        Demographic: [
            { name: 'Age', value: '35' },
            { name: 'Location', value: 'Toorak' },
            { name: 'Gender', value: 'Male' },

        ],
        'Sales Data': [
            { name: 'Average spend', value: '$38.95' },
            { name: 'Last item purchased', value: 'Red Wool jumper' },
            { name: 'Last amount purchased', value: '$45.99' },
            { name: 'Number of purchases', value: '4' },
        ],
        Pyschographic: []
    }

    useEffect(() => {
        fetchEvents()
        fetchDatapoints()
    }, [])

    const fetchEvents = async () => {
        axios.get('https://asia-southeast1-oss-demo-au.cloudfunctions.net/fetchEvents').then(resp => setEvents(resp.data.data)).catch(e => console.error('error in fetch events', e))
    }

    const fetchDatapoints = async () => {
        axios.get('https://asia-southeast1-oss-demo-au.cloudfunctions.net/fetchDatapoints').then(resp => setDatapoints(resp.data.data)).catch(e => console.error('error in fetch datapoints', e))
    }

    const toggleDropdown = name => {
        if (openedDropdowns.includes(name)) {
            setOpenedDropdowns(openedDropdowns.filter(row => row !== name))
        } else {
            setOpenedDropdowns([...openedDropdowns, name])
        }
    }



    return (<Wrapper>
        <Sidebar />
        <MainSection>

            <PageTitle>Customer Activity</PageTitle>
            <SubTitle>Unify your customer data</SubTitle>
            <Row>
                <Box>
                    <SectionTitle>Customers</SectionTitle>
                    <SectionSubTitle>Customers</SectionSubTitle>
                    <CustomerSection>
                        {customers.map(({ name, id }, index) => <CustomerBox key={'customer' + index} active={selectedCustomer && selectedCustomer.id === id} onClick={() => setSelectedCustomer({ id, name })}>
                            <CustomerImageBlue>
                                <CustomerImage src={customerImg}></CustomerImage>
                            </CustomerImageBlue>
                            <CustomerName active={selectedCustomer && selectedCustomer.id === id}>{name}</CustomerName>
                        </CustomerBox>)}
                    </CustomerSection>
                </Box>
                {selectedCustomer && <Box noPadding>
                    <BoxHeader>
                        <Row style={{ alignItems: 'center' }}>
                            <CustomerImage src={customerImg}></CustomerImage>
                            <CustomerName active style={{ fontSize: '22px', marginLeft: '10px', marginTop: '5px', fontWeight: '700' }}>{selectedCustomer.name}</CustomerName>
                            <ViewProfileButton>View Full Profile</ViewProfileButton>
                        </Row>
                        <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                            <CustomerName style={{ marginTop: 0, color: 'white', fontSize: '17px', marginRight: '10px' }}>Collected Datapoints</CustomerName>
                            <img src={information} style={{ width: '26px', height: '26px' }} />
                        </Row>
                    </BoxHeader>
                    <TimelineSection>
                        {
                            singleViewSections.map(row =>
                                <div key={row.name}>
                                    <Row timeline title borderColor={row.borderColor} onClick={() => toggleDropdown(row.name)}>
                                        <TimelineIcon src={row.img} />
                                        <TimelineLabel>{row.name}</TimelineLabel>
                                        <DropdownArrow src={downArrow} open={openedDropdowns.includes(row.name)} />
                                    </Row>
                                    {openedDropdowns.includes(row.name) &&
                                        (row.name === 'Timeline' ? <>
                                            {
                                                events.map((eventRow, index) =>
                                                    <Row timeline borderColor={row.borderColor}>
                                                        <Content key={eventRow.name + index}>
                                                            <TimelineLabel date>{eventRow.date}</TimelineLabel>
                                                            {eventRow.activity.map(activity => <Content key={activity.id} style={{
                                                                display: 'flex', marginTop: '20px',
                                                                width: 'calc(100% - 20px)'
                                                            }}>
                                                                <ContentLabel style={{ width: '100px' }}>
                                                                    {format(new Date(activity.unix * 1000), 'hh:mm a')}
                                                                </ContentLabel>
                                                                <ContentLabel style={{ marginLeft: '30px', maxWidth: 'calc(100% - 200px)' }}>
                                                                    {activity.type === 'page_visit' ? `Viewed Page: ${activity.url} ` : activity.type === 'add_to_cart' ? `Added ${activity.productName} to cart.` : `Checkout: ${activity.cartTotal}`}
                                                                </ContentLabel>
                                                            </Content>)}
                                                        </Content>
                                                    </Row>)
                                            }
                                        </> :
                                            contentForSections[row.name] && contentForSections[row.name].filter(content => !['Age', 'Location'].includes(content.name) || datapoints[content.name]).map((content, index) =>
                                                <Row timeline borderColor={row.borderColor}>
                                                    <Content key={content.name + index}>
                                                        <Row style={{ width: '100%', }}>
                                                            <ContentLabel>{content.name}</ContentLabel>
                                                            <ContentLabel right>{content.value}</ContentLabel>
                                                        </Row>
                                                    </Content>
                                                </Row>))}
                                </div>)
                        }
                    </TimelineSection>

                </Box>}
            </Row>
        </MainSection>
    </Wrapper >)

}

export default Dashboard